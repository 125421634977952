import { useMutation, useQueryClient} from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";

import { useCommon } from "../../../components/Context/CommonContext";
import { TGroupDiscount } from "@/components/forms/AddGroupDiscountForm";


const addGroupDiscount = async (data:TGroupDiscount) => {
    const url = BASE_PATH + `api/group-discount/`;
    const res = await fetchWrapper.post(url,data);
    return res;
};

export const useAddGroupDiscount= () => {
  const { dispatch } = useCommon();
  const queryClient= useQueryClient();
  return useMutation({
    mutationFn: (data: TGroupDiscount) => addGroupDiscount(data),
    onSuccess: () => {
      dispatch({
        type: "toast",
        payload: {
          visible: true,
          message: "Successfully created",
          success: true,
        },
      });
      queryClient.invalidateQueries({ queryKey:[`group_discount`]});
    },
    onError:(data:any)=>{
      for (const key in data) {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: `${data[key][0]}`,
            success: false,
          },
        });
      }
    },
  });
};
