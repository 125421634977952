import { useMutation, useQueryClient } from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";
import { useCommon } from "../../../components/Context/CommonContext";

type TBlogEditProps={
    blog_id:number,
    data:FormData
}

const patchBlog = async ({blog_id,data}:TBlogEditProps) => {
  const url = BASE_PATH + `api/blog/${blog_id}/`;
  const res = await fetchWrapper.formPatch(url, data);
  return res.data;
};

export const usePatchBlog = () => {
  const { dispatch } = useCommon();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data:TBlogEditProps) => patchBlog(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`Blogs`] });
      dispatch({
        type: "toast",
        payload: {
          visible: true,
          message: "Successfully Updated",
          success: true,
        },
      });
    },
    onError: (data: any) => {
      for (const key in data) {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: `${data[key][0]}`,
            success: false,
          },
        });
      }
    },
  });
};
