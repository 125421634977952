"use client";
import { Form } from "react-bootstrap";
import { UseFormRegister } from "react-hook-form";

interface Option {
  name: string;
  id: string;
}

interface IDropDown {
  label?: string;
  name: string;
  options: Option[];
  placeholder?: string;
  register: UseFormRegister<any>;
  [key: string]: any;
}

const DropDown = (props: IDropDown) => {
  const {
    label,
    options,
    register,
    validate,
    name,
    error,
    onChange,
    placeholder,
    selectedValues,
    isMultiple = true,
    ...args
  } = props;

  return (
    <div>
      {label && <Form.Label className="mt-2 fw-bold">{label}</Form.Label>}
      <Form.Select
        aria-label="Options"
        {...register(`${name}`, validate)}
        multiple={isMultiple}
        defaultValue={selectedValues}
      >
        <option disabled value="">
          {placeholder || `Select ${name}`}
        </option>

        {options?.map((option: Option) => (
          <option
            key={option?.id}
            value={option?.id}
            selected={option.id === selectedValues}
          >
            {option?.name}
          </option>
        ))}
      </Form.Select>

      {error && <p className="text-base text-white mt-4">*{error}</p>}
    </div>
  );
};

export default DropDown;
