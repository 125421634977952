import React from 'react'
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
type TProps= {
  value:string;
  onChange?:any;
}
const Editor = ({value,onChange}:TProps) => {
 const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link','formula'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                       // remove formatting button
  ];
  
  
  const  modules ={
      toolbar: toolbarOptions
    }

  return <ReactQuill modules={modules} theme="snow" value={value}
    onChange={onChange} />
}
  export default Editor;