import AddModalComponent from "../../components/Modal/AddModalComponent";
import { useEffect, useState } from "react";
import { PaginationState, createColumnHelper } from "@tanstack/react-table";
import { getActiveCompanyId, getFormatedLocalDateTime, isSuperUser } from "../../common/Utils";
import TableWithSeverSidePagination from "../../components/ui/Table";
import AddGroupDiscountForm from "../../components/forms/AddGroupDiscountForm";
import { useGetGroupDiscounts } from "../../hooks/api/GroupDiscount/getGroupDiscountList";
import Button from "../../components/Button";
import useDebounceFunc from "../../hooks/useDebounceFunc";
const columnHelper = createColumnHelper<any>();

const GroupDiscounts = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const handleSearch = useDebounceFunc((value: string) => {
    setSearchValue(value);
  }, 700);
  const [showAddModel, setShowAddModel] = useState(false);
  const [activeDiscount, setActiveDiscount] = useState<
    number | null
  >(null);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const { data, refetch, isLoading } = useGetGroupDiscounts(pagination, searchValue);

  const onHide = () => {
    setActiveDiscount(null);
    setShowAddModel(!showAddModel);
  };
  const handleActiveDiscount = (id: number) => {
    setShowAddModel(!showAddModel);
    setActiveDiscount(id);
  };

  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("sn", {
      header: "SN",
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor("consumer.name", {
      header: `Consumer`,
      cell: (info) => {
        return <>{info?.getValue()}</>;
      },
    }),
    columnHelper.accessor("provider.name", {
      header: `Provider`,
      cell: (info) => {
        return <>{info?.getValue()}</>;
      },
    }),
    columnHelper.accessor("discount", {
      header: `Discount`,
      cell: (info) => {
        return <>{info?.getValue()} %</>;
      },
    }),
    columnHelper.accessor("user_type", {
      header: `User type`,
      cell: (info) => {
        return <>{info?.getValue()}</>;
      },
    }),
    columnHelper.accessor("created_at", {
      header: `Created on`,
      cell: (info) => getFormatedLocalDateTime(info.getValue()),
    }),
    columnHelper.accessor("expiry_date", {
      header: `Expires on`,
      cell: (info) => getFormatedLocalDateTime(info.getValue()),
    }),


  ];

  {
    getActiveCompanyId() && isSuperUser() && columns.push(
      columnHelper.accessor("action", {
        header: `Actions`,
        cell: (info) => {
          return (
            <div className="d-flex align-items-center gap-2">
              <Button
                title="Edit"
                className="btn btn-link"
                iconType="edit"
                onClick={() => handleActiveDiscount(info.row.original.id)}
              />

              {/* <Button
              title="Delete"
              className="btn btn-link"
              iconType="delete"
              onClick={() => handledeleteScratchReward(info.row.original.id)}
            /> */}
            </div>
          );
        },
      }),
    )
  }
  
  useEffect(() => {
    refetch();
  },[searchValue]);


  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div>
          <TableWithSeverSidePagination
            columns={columns}
            data={data?.results}
            rowCount={data?.count}
            pagination={pagination}
            setPagination={setPagination}
            primaryButtonLabel={"Add Group Discount"}
            primaryButtonAction={isSuperUser() ? onHide : () => null}
            title="Group Discount"
            isLoading={isLoading}
            hasSearch={true}
            handleSearch={handleSearch}
          />
          {showAddModel && (
            <AddModalComponent
              show={showAddModel}
              title={`${activeDiscount ? `Update` : `Add`
                } Group Discount`}
              onHide={onHide}
            >
              <AddGroupDiscountForm
                activeDiscount={activeDiscount}
                onHide={onHide}
              />
            </AddModalComponent>
          )}
        </div>
      </div>
    </>
  );
};

export default GroupDiscounts;
