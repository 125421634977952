import React from "react";
import { useNavigate } from "react-router-dom";
import AdminService, {
  getUserData,
  postLogin,
  setTokenToSession,
} from "../services/AdminService";
import {
  saveActiveCompanyToLocalStorage,
  setAdminUserData,
} from "../common/Utils";
import { useLocalStorage } from "../hooks/useLocalStorage";

const AuthContext = React.createContext<any>(null);

export const useAuth = (): any => {
  return React.useContext(AuthContext);
};

export const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const { setItem } = useLocalStorage("loyalty-admin-username");
  const [token, setToken] = React.useState<any>(AdminService.userValue);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleLogin = (
    username: string,
    password: string,
    dispatch: any,
    isRememberMe: boolean
  ) => {
    dispatch({ type: "loading", payload: true });
    postLogin({
      username: username,
      password: password,
    })
      .then((response) => {
        if (response !== null) {
          if (isRememberMe) {
            setItem(username);
          }
          setTokenToSession(response);
          saveActiveCompanyToLocalStorage(
            response.user.company_roles.length > 0
              ? response.user.company_roles[0].company
              : null
          );
          setToken(response);
        } else {
          dispatch({ type: "loading", payload: false });
          dispatch({
            type: "toast",
            payload: {
              visible: true,
              message: "Wrong Credentials",
              success: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: "loading", payload: false });

        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: error.detail,
            success: false,
          },
        });
      })
      .finally(() => {
        getUserData()
          .then((response) => {
            setAdminUserData(response);
          })
          .catch((error) => {
            dispatch({ type: "loading", payload: false });

            dispatch({
              type: "toast",
              payload: {
                visible: true,
                message: error.detail,
                success: false,
              },
            });
          });
        navigate("/");
        dispatch({ type: "loading", payload: false });
      });
  };

  const handleLogout = (): boolean => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to logout ?")) {
      AdminService.postLogout();
      setToken(null);
    }
    return true;
  };

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    session: token,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
