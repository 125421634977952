import {
  API_ACCOUNT,
  API_CATEGORY,
  API_FORGET_PASSWORD,
  API_GET_ACCOUNT,
  API_GET_TOKEN,
  API_GET_USER_DETAIL,
  API_RESET_PASSWORD,
  BASE_PATH,
  setCategoryUrl,
} from "../common/Constants";
import { BehaviorSubject } from "rxjs";
import fetchWrapper from "../common/FetchWrapper";
const isBrowser = typeof window !== "undefined";
const sessionKey = "admin-user";

const userSubject = new BehaviorSubject(
  isBrowser &&
    (localStorage.getItem(sessionKey) != null
      ? JSON.parse(localStorage.getItem(sessionKey) || "")
      : null)
);

export const postLogin = async (data: {
  username: string;
  password: string;
}): Promise<any> => {
  const user = await fetchWrapper.post(`${BASE_PATH}${API_GET_TOKEN}`, data);
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  user.email = data.username;
  userSubject.next(user);
  localStorage.setItem(sessionKey, JSON.stringify(user));
  return user;
};

const postLogout = (): boolean => {
  localStorage.removeItem(sessionKey);
  userSubject.next(null);
  return true;
};

export const setTokenToSession = (data: {
  refresh: string | null;
  access: string | null;
}): boolean => {
  localStorage.setItem(sessionKey, JSON.stringify(data));
  return true;
};

export const forgetPassword = async (email: string): Promise<any> => {
  return await fetchWrapper.post(`${BASE_PATH}${API_FORGET_PASSWORD}`, {
    email,
  });
};

export const resetPassword = async (
  new_password1: string | null,
  code: string | null
): Promise<any> => {
  return await fetchWrapper.post(`${BASE_PATH}${API_RESET_PASSWORD}`, {
    new_password1,
    code,
  });
};
export const getUserData = async (): Promise<any> => {
  return await fetchWrapper.get(`${BASE_PATH}${API_GET_USER_DETAIL}`);
};

export const getUserDetail = async (uid: string): Promise<any> => {
  return await fetchWrapper.get(BASE_PATH + API_GET_ACCOUNT + `${uid}`);
};

export const createCustomer = async (
  cid: number,
  data: any,
  isEmployeeCreation: boolean
) => {
  data.company_id = cid;

  if (isEmployeeCreation)
    return await fetchWrapper.post(`${BASE_PATH}api/account/?employee=true`, data);
  else return await fetchWrapper.post(`${BASE_PATH}api/account/`, data);
};

export const stampCustomerLoyaltyCard = async (cid: number, data: any) => {
  return await fetchWrapper.post(
    `${BASE_PATH}api/company/${cid}/userloyaltycard/stamp/`,
    data
  );
};

export const searchUserList = async (username: string, page_size: number) => {
  return await fetchWrapper.get(
    BASE_PATH + `${API_ACCOUNT}?user=` + username + `&page_size=` + page_size
  );
};

export const companyCategoryList = async () => {
  return await fetchWrapper.get(BASE_PATH + API_CATEGORY);
};

export const setCompanyCategory = async (
  companyId: number,
  categoryId: number
) => {
  const formData = new FormData();
  formData.append("category", String(categoryId));
  return await fetchWrapper.formPost(
    BASE_PATH + setCategoryUrl(companyId),
    formData
  );
};

export const getDataSeries = async (
  cid: number,
  startDate: string,
  endDate: string,
  viewBy: string,
  loyaltyId: any
) => {
  // eslint-disable-next-line max-len
  let url = `${BASE_PATH}api/dashboard/company/${cid}/data-series/?from=${startDate}T00:00:00Z&to=${endDate}T23:59:59Z&report_of=${viewBy}`;

  if (loyaltyId != null) {
    url = url + `&loyalty_card=${loyaltyId}`;
  }

  return await fetchWrapper.get(url);
};

const AdminService = {
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  postLogout,
};

export default AdminService;
