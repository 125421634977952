import React, { Suspense, lazy, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import LoadingView from "../LoadingView";
const Footer = lazy(() => import("../Footer/Footer"));
const Header = lazy(() => import("../Header/Header"));
const Sidebar = lazy(() => import("../Sidebar/Sidebar"));

const MainLayout = () => {
  const [showSideBar, setShowSideBar] = useState<boolean>(
    localStorage.getItem("IsShowSideBar") === "true"
  );

  useEffect(() => {
    localStorage.setItem("IsShowSideBar", String(showSideBar));
  }, [showSideBar]);
  return (
    <>
      <Suspense fallback={<LoadingView />}>
        <Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
        <div className={`content  ${!showSideBar ? "open" : ""}`}>
          <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
          <Outlet />
          <Footer />
        </div>
      </Suspense>
    </>
  );
};
export default MainLayout;
