import { useQuery } from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";
import { useCommon } from "../../../components/Context/CommonContext";

const getBlogDetail = async (blog_id: number | null) => {
  if (blog_id) {
      const url = BASE_PATH + `api/blog/${blog_id}/`;
      const res = await fetchWrapper.get(url);
      return res;
  }
};

export const useGetBlogDetails = (blog_id: number | null) => {
  const { dispatch } = useCommon();
  return useQuery({
    queryKey: [`blog_id ${blog_id}`],
    queryFn: () => getBlogDetail(blog_id),
    enabled: !!blog_id,
    // onError: (data: any) => {
    //   for (const key in data) {
    //     dispatch({
    //       type: "toast",
    //       payload: {
    //         visible: true,
    //         message: `${data[key][0]}`,
    //         success: false,
    //       },
    //     });
    //   }
    // },
  });
};
