import React from "react";
const FilterBy = ({
  options,
  setViewBy,
  title,
  dataChanged,
}: any) => {
  const handleOption = (e: React.ChangeEvent<HTMLSelectElement>): any => {
    const value = e.target.value;
    if (parseInt(value) === -1) {
      setViewBy(null);
      dataChanged(null);
    } else {
      setViewBy(e.target.value);
      if (dataChanged != undefined) dataChanged(e.target.value);
    }
  };
  return (
    <>
      <div className="d-flex align-items-center me-2">
        <label className="text-end me-2">
          <b>{title ? title : "Filter"}</b>
        </label>
        <select
          className="form-select"
          aria-label=""
          onChange={(e) => {
            handleOption(e);
          }}
        >
          <option value={-1}>All</option>
          {options.map((node: any) => (
            <option key={node.id} value={node.id}>
              {node.name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
export default FilterBy;
