import { PaginationState, createColumnHelper } from "@tanstack/react-table";
import { getFormatedLocalDateTime, isSuperUser } from "../../common/Utils";
import { useEffect, useState } from "react";
import TableWithSeverSidePagination from "../../components/ui/Table";
import { useGetCreditLog } from "../../hooks/api/ScratchCardCredit/getScratchCardCreditLogs";
import useDebounceFunc from "../../hooks/useDebounceFunc";
import HeadingTitle from "../../components/HeadingTitle";
import { Stack } from "react-bootstrap";
import FilterBy from "../../components/Filtering/FilterBy";
import SearchBar from "../../components/SearchBar";
import Button from "../../components/Button";
import AddModalComponent from "../../components/Modal/AddModalComponent";
import AddCreditForm from "../../components/forms/AddCreditForm";
import { useParams } from "react-router-dom";

const columnHelper = createColumnHelper<any>();
export type TLogFilterData = {
  log_type: string | null,
  searchValue: string
}

const CreditLog = () => {
  const { id }: any = useParams();
  const [showAddModel, setShowAddModel] = useState(false);
  const [filterOptions, setFilterOptions] = useState<TLogFilterData>({
    log_type: null,
    searchValue: "",
  });
  const handleSearch = useDebounceFunc((value: string) => {
    setFilterOptions((prev) => ({ ...prev, searchValue: value }));
  }, 700);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const { data, refetch } = useGetCreditLog(filterOptions, pagination,id);

  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("content_type", {
      header: `Activity Log Type`,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor("user.username", {
      header: `Username`,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor("credit", {
      header: "Credit",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("debit", {
      header: `Debit`,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor("balance", {
      header: `Balance`,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor("created_at", {
      header: `Created At`,
      cell: (info) => {
        return (
          <div className="flex items-center gap-2 font-semibold">
            {getFormatedLocalDateTime(info.getValue())}
          </div>
        );
      },
    }),
  ];

  const onHide = () => {
    if (isSuperUser()) {
      setShowAddModel(!showAddModel);
    }
  };

  useEffect(() => {
    refetch();
  }, [filterOptions, pagination]);



  return (
    <>
      <div className="container-fluid pt-4 px-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <HeadingTitle title="Credit Log" />
          <Stack direction="horizontal">
            <FilterBy
              title={"Type"}
              options={[
                { id: "credits", name: "Credits" },
                { id: "transactions", name: "Credit Transactions" },
              ]}
              setViewBy={() => null}
              dataChanged={(data: any) => {
                setFilterOptions((prev) => ({ ...prev, log_type: data ? data : null }));
              }}
            />
            {
              <SearchBar
                setSearchValue={handleSearch}
                placeholder=" Search..."
                hideSearchButton={false}
              />
            }

            {isSuperUser() && <Button
              iconType="add"
              title="Add Credit"
              className="btn btn-primary"
              onClick={onHide}
            />}
          </Stack>
        </div>
        <TableWithSeverSidePagination
          columns={columns}
          data={data?.results}
          rowCount={data?.count}
          title=""
          pagination={pagination}
          hasSearch={false}
          setPagination={setPagination}
          handleSearch={() => null}
        />
        {showAddModel && (
          <AddModalComponent
            show={showAddModel}
            title={'Add Merchant Credit'}
            onHide={onHide}
          >
            <AddCreditForm
              onHide={onHide}
            />
          </AddModalComponent>
        )}
      </div>
    </>
  );
};

export default CreditLog;
