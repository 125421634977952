import { useMutation, useQueryClient} from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";

import { useCommon } from "../../../components/Context/CommonContext";


type TgetCompanyScratchCardCredit={
  company_id:number;
  data:any
}

const getCompanyScratchCardCredit = async ({company_id,data}:TgetCompanyScratchCardCredit) => {
    const url = BASE_PATH + `api/company/${company_id}/merchant-credit/`;
    const res = await fetchWrapper.post(url,data);
    return res;
};

export const useAddCompanyScratchCardCredit= () => {
  const { dispatch } = useCommon();
  const queryClient= useQueryClient();
  return useMutation({
    mutationFn: (data: TgetCompanyScratchCardCredit) => getCompanyScratchCardCredit(data),
    onSuccess: (data:any) => {
      dispatch({
        type: "toast",
        payload: {
          visible: true,
          message: "Successfully created",
          success: true,
        },
      });
      queryClient.invalidateQueries({ queryKey:[`credit_logs`]});
    },
    onError:(data:any)=>{
      for (const key in data) {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: `${data[key][0]}`,
            success: false,
          },
        });
      }
    },
  });
};
