import React from "react";
import { Link } from "react-router-dom";
import {
  PaginationState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Table as BootstrapTable, Form } from "react-bootstrap";
import { cn } from "../../common/Utils";
import HeadingTitle from "../HeadingTitle";
import Button from "../Button";



const TableHead = (props: { table: any; headerHasBackground: boolean }) => {
  const { table, headerHasBackground } = props;

  return (
    <thead className="font-medium">
      {table.getHeaderGroups().map(
        (headerGroup: {
          id: React.Key | null | undefined;
          headers: {
            column: { columnDef: any };
            id: React.Key | null | undefined;
            isPlaceholder: any;
            getContext: () => any;
          }[];
        }) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(
                (
                  header: {
                    column: { columnDef: any };
                    id: React.Key | null | undefined;
                    isPlaceholder: any;
                    getContext: () => any;
                  },
                ) => {
                  const { columnDef } = header.column;

                  return (
                    <th
                      scope="col"
                      key={header.id}
                      className={cn(
                        headerHasBackground ? "bg-gray-50" : "bg-white",
                        "bordered"
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(columnDef.header, header.getContext())}
                    </th>
                  );
                }
              )}
            </tr>
          );
        }
      )}
    </thead>
  );
};

const TableBody = (props: any) => {
  const { table } = props;

  return (
    <tbody className="bg-white text-xs">
      {table.getRowModel().rows.map(
        (
          row: {
            id: React.Key | null | undefined;
            getVisibleCells: () => any[];
            original: any;
          },
          idx: any
        ) => {
          const { isSuspended } = row.original;
          return (
            <tr
              key={row.id}
              className={cn(
                idx % 2 === 0
                  ? "bg-white cursor-pointer"
                  : "bg-gray-30 cursor-pointer"
              )}
            // onClick={() => router.push(pathname + "/" + row?.original?.id)}
            >
              {row.getVisibleCells().map((cell) => {
                const { columnDef } = cell.column;
                return (
                  <td
                    key={cell.id}
                    className={cn(
                      "py-2",
                      isSuspended
                        ? "text-red-300 line-through"
                        : "text-gray-400"
                    )}
                  >
                    {flexRender(columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        }
      )}
    </tbody>
  );
};
export const pages = [10, 20, 30, 50, 80, 100];
interface ITable {
  data: any[];
  columns: any[];
  title: string;
  link?: string;
  filterButton?: React.ReactElement;
  hasPrimaryButton?: boolean;
  primaryButtonLabel?: string;
  primaryButtonAction?: () => void;
  primaryButtonDisabled?: boolean;
  isLoading?: boolean;
  hasSearch?: boolean;
  showPagination?: boolean;
  showLimit?: boolean;
  headerHasBackground?: boolean;
  rowCount: number;
  pagination: PaginationState;
  setPagination: any;
  handleSearch: (value: any) => void;
}

export default function TableWithSeverSidePagination({
  data,
  columns,
  title,
  link,
  filterButton,
  hasPrimaryButton = true,
  primaryButtonLabel,
  primaryButtonAction,
  primaryButtonDisabled = false,
  isLoading,
  hasSearch = true,
  showPagination = true,
  showLimit = true,
  headerHasBackground = true,
  rowCount,
  pagination,
  setPagination,
  handleSearch
}: ITable) {

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility: {
        id: false,
      },
      pagination,
    },
    manualPagination: true,
    rowCount,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    autoResetPageIndex: false, //turn off auto reset of pageIndex
  });

  return (
    <div>
      <div className="mb-2 d-flex justify-content-between">
        {title && <HeadingTitle className="py-0" title={title} />}
        <div className="d-flex gap-2">
          {hasSearch && (
             <input
             className="form-control"
             placeholder="Search..."
             type="search"
             onChange={(e: any) => handleSearch(e.target.value)}
           />

          )}
          {filterButton && React.cloneElement(filterButton)}
          {hasPrimaryButton && (
            <div className="flex-auto w-full ">
              {link && primaryButtonLabel && (
                <Link to={link} className="">
                  <Button
                    iconType="add"
                    title={primaryButtonLabel}
                    className="btn btn-primary"
                  />
                </Link>
              )}
              {primaryButtonAction && primaryButtonLabel && (
                <Button
                  iconType="add"
                  title={primaryButtonLabel}
                  className="btn btn-primary"
                  onClick={primaryButtonAction}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <BootstrapTable striped borderless responsive hover>
        <TableHead table={table} headerHasBackground={headerHasBackground} />

        {data && data.length ? (
          <TableBody table={table} />
        ) :
          isLoading ? (
            <tbody>
              <tr>
                <td colSpan={12}>
                  <p className="animate-pulse p-4 text-center text-xs">
                    {"Please wait. Data is loading..."}
                  </p>
                </td>
              </tr>
            </tbody>
          ) :
            (
              <tbody>
                <tr>
                  <td colSpan={12}>
                    <p className="p-4 text-sm  text-center text-gray-500">{"No data found."}</p>
                  </td>
                </tr>
              </tbody>
            )}
      </BootstrapTable>

      {showPagination && rowCount>50 && (
        <nav
          className="d-flex justify-content-between align-items-center px-2 sm:px-6"
          aria-label="Pagination"
        >
          <div className="d-flex align-items-center">
            <p className="mb-0">
              Page
              <span className="font-medium ms-1">
                {table.getState().pagination.pageIndex + 1}
              </span>
              &nbsp; {"of"} &nbsp;
              <span className="font-medium">{table.getPageCount()}</span>
            </p>
            {showLimit && (
              <div className="ms-3">
                <Form.Select
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                    table.setPageIndex(0);
                  }}
                  className="form-control py-1"
                >
                  {pages?.map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </Form.Select>
              </div>
            )}
          </div>
          <div>
            Showing {table.getRowModel().rows.length.toLocaleString()} of{" "}
            {rowCount.toLocaleString()} Rows
          </div>

          <div className="flex flex-1 justify-between sm:justify-end">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className="relative btn btn-info text-white"
            >
              {"Previous"}
            </button>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className="btn btn-primary ms-2 "
            >
              {"Next"}
            </button>
          </div>
        </nav>
      )}
    </div>
  );
}
