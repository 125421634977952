import {  useQuery} from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";
import { PaginationState } from "@tanstack/react-table";
import { createQueryString, getActiveCompanyId } from "../../../common/Utils";

const getGroupDiscountList =
 async (pagination:PaginationState,searchValue:string) => {
  const query= createQueryString(pagination,searchValue);
  const url = BASE_PATH + `api/company/${getActiveCompanyId()}/group-discount/?${query}`;
  const res = await fetchWrapper.get(url);
  return res;  
};

export const useGetGroupDiscounts = (pagination:PaginationState,searchValue:string) => {
  return useQuery({
    queryKey: [`group_discount`],
    queryFn:()=> getGroupDiscountList(pagination,searchValue),
  });
};
