"use client";
import * as React from "react";
import { UseFormRegister } from "react-hook-form";
import { Form } from "react-bootstrap";
import { cn } from "../../common/Utils";

export interface InputProps {
  error?: any;
  label?: string | null;
  register: UseFormRegister<any>;
  name: string;
  labelClassName?: string;
  className?: string;
  type: string;
  as?: "textarea";
  rows?: number;
  validate?: object;
  placeholder?: string;
  icon?: React.ReactElement;
  disabled?: boolean;
  pattern?: string;
  step?: string;
}

const Input: React.FC<InputProps> = ({
  labelClassName = "",
  type,
  error,
  label,
  name,
  icon,
  className = "",
  validate,
  register,
  step,
  ...props
}) => {
  return (
    <div className="flex flex-col position-relative">
      <Form.Group>        
      {label && (
        <Form.Label htmlFor={name} className="mt-2 fw-bold">
          {label}
        </Form.Label>
      )}
      <Form.Control
        type={type}
        aria-describedby={name}
        className={cn("form-control", className)}
        {...register(`${name}`, validate)}
        step={step}
        {...props}
      />
      <span className="position-absolute top-0 mt-1 ps-2">
        {icon &&
          React.cloneElement(icon, {
            className: "position-absolute ",
          })}
      </span>
      {error && <p className="text-base text-danger">*{error}</p>}
      </Form.Group>
    </div>
  );
};
Input.displayName = "Input";

export { Input };
