import React from "react";
import { UseFormRegister } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

const ImageUpload = ({
  register,
  name,
  error,
  file,
  validate,
  buttonLabel,
  label,
}: any) => {
  const uploadButtonLabel =
    file && file.length > 0 && file[0]
      ? `Change ${buttonLabel ? buttonLabel : "image"}`
      : `Upload ${buttonLabel ? buttonLabel : "image"}`;

  const handleImageUrl = (file?: any) => {
    if (file !== "") {
      let image = "";
      if (file && file.length > 0 && file[0] instanceof File) {
        image = URL.createObjectURL(file[0]);
      } else if (typeof file === "string") {
        image = file;
      }

      return image;
    }
  };
  const imageUrl = handleImageUrl(file);
  return (
    <>
      <div
        className="bg-light2  border-1 rounded-4 position-relative border"
        style={{ height: "8rem" }}
      >
        {Boolean(imageUrl) && (
          <img
            src={imageUrl}
            alt="banner_image"
            className="w-100 h-100 rounded"
            style={{ objectFit: "cover" }}
          />
        )}
        {
          // eslint-disable-next-line no-extra-boolean-cast
          !Boolean(imageUrl) ? (
            <div className="position-absolute top-50 start-50 translate-middle">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <input
                  type="file"
                  id="actual-btn"
                  name={name}
                  {...register(`${name}`)}
                  accept="image/png, image/jpeg, image/jpg, image/webp"
                  hidden
                />
                <FontAwesomeIcon icon={faImage} size="2x" />
                <h5 className="text-center">{uploadButtonLabel}</h5>
                <label htmlFor="actual-btn" className="choosefile">
                  <span className="text-center btn fw-bold border rounded-4">
                    Browse
                  </span>
                </label>
              </div>
            </div>
          ) : (
            <div className="position-absolute end-0" style={{ top: "10px" }}>
              <input
                type="file"
                id="actual-btn"
                name={name}
                {...register(`${name}`)}
                accept="image/png, image/jpeg, image/jpg,image/webp"
                hidden
              />
              <label htmlFor="actual-btn" className="choosefile pointer me-2 ">
                <span className="text-center btn fw-bold border bg-white rounded-4">
                  Browse
                </span>
              </label>
            </div>
          )}
      </div>

      {error && <p className="text-base text-danger">*{error}</p>}
    </>
  );
};

export default ImageUpload;
