import { useQuery } from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";

import { PaginationState } from "@tanstack/react-table";
import { getActiveCompanyId } from "../../../common/Utils";
import { TLogFilterData } from "@/pages/CreditLog/CreditLog";

export const useGetCreditLog = (
  filterOptions: TLogFilterData,
  pagination: PaginationState,
  id?: number
) => {
  return useQuery({
    queryKey: [`credit_logs`],
    queryFn: () => getCreditLog(filterOptions, pagination,id),
  });
};

const getCreditLog = async (
  filterOptions: TLogFilterData,
  { pageIndex, pageSize }: PaginationState,
  id?: any
) => {
  const query = new URLSearchParams();
  query.append("page", String(pageIndex + 1));
  query.append("page_size", String(pageSize));

  if (filterOptions?.searchValue)
    query.append("search", filterOptions?.searchValue);
  if (filterOptions?.log_type) {
    query.append("log_type", String(filterOptions?.log_type));
  }
  const url =
    BASE_PATH +
    `api/company/${id ? id : getActiveCompanyId()}/merchant-credit/?${query}`;
  const res = await fetchWrapper.get(url);
  return res;
};
