import { useQuery } from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";
import { getActiveCompanyId } from "../../../common/Utils";

const getGroupDiscountDetail = async (group_discount_id: number | null) => {
  const url = BASE_PATH + `api/company/${getActiveCompanyId()}/group-discount/${group_discount_id}/`;
  const res = await fetchWrapper.get(url);
  return res;
};

export const useGetGroupDiscountDetail = (group_discount_id: number | null) => {
  return useQuery({
    queryKey: [`group_discount`, group_discount_id],
    queryFn: () => getGroupDiscountDetail(group_discount_id),
    enabled: !!group_discount_id,
  });
};
