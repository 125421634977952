import React from "react";
import { classNames } from "../common/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faEdit,
  faEye,
  faPlusCircle,
  faTrash,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import Lottie from "lottie-react";
import loadingAnimation from "../../src/lottie/loading.json";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type ButtonProps = {
  type?: "button" | "submit" | "reset" | "link" | any;
  loading?: boolean;
  disabled?: boolean;
  label?: string | React.ComponentProps<"svg">;
  className?: string;
  iconType?: string;
  title?: string;
  [key: string]: any;
};

/**
 * This component generates button type and icon type button
 * @param className btn btn-primary className for button type and btn btn-link for icon type
 * @param iconType generates icon for buttons and for icon too.
 * @param label to add text inside buttons
 * @returns
 */
export default function Button({
  type = "button",
  loading = false,
  disabled = false,
  label,
  className = "text-secondary",
  iconType,
  title,
  ...props
}: ButtonProps) {
  let icon = <></>;
  switch (iconType) {
    case "add":
      icon = <FontAwesomeIcon icon={faPlusCircle} />;
      break;
    case "edit":
      icon = <FontAwesomeIcon icon={faEdit} />;
      break;
    case "delete":
      icon = <FontAwesomeIcon icon={faTrash} color="red" />;
      break;
    case "enable":
      icon = <FontAwesomeIcon icon={faCircleCheck} />;
      break;
    case "disable":
      icon = <FontAwesomeIcon icon={faCircleXmark} />;
      break;
    case "view":
      icon = <FontAwesomeIcon icon={faEye} />;
      break;
    case "image":
      icon = <FontAwesomeIcon icon={faImage} />;
      break;
  }

  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {title ?? title}
    </Tooltip>
  );
  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <button
          disabled={disabled}
          type={type}
          className={classNames(className)}
          data-toggle="tooltip"
          data-placement="bottom"
          {...props}
        >
          <>
            {loading && (
              <div
                id="spinner"
                className="show bg-white position-relative"
                style={{ zIndex: "100" }}
              >
                <Lottie animationData={loadingAnimation} />
              </div>
            )}
            {label && label}
            <span className="ms-0">{iconType && icon}</span>
          </>
        </button>
      </OverlayTrigger>
    </>
  );
}
