import React from "react";

const HeadingTitle = ({ title }: any) => {
  return (
    <>
      <h4>{title}</h4>
    </>
  );
};

export default HeadingTitle;
