import { useMutation, useQueryClient} from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";
import { useCommon } from "../../../components/Context/CommonContext";

const addBlog = async (data: FormData) => {
    const url = BASE_PATH + `api/blog/`;
    const res = await fetchWrapper.formPost(url, data);
    return res;
};

export const useAddBlog = () => {
  const { dispatch } = useCommon();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: FormData) => addBlog(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`Blogs`] });
      dispatch({
        type: "toast",
        payload: {
          visible: true,
          message: "Successfully created",
          success: true,
        },
      });
    },
    onError: (data: any) => {
      for (const key in data) {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: `${data[key][0]}`,
            success: false,
          },
        });
      }
    },
  });
};
