"use-client";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "../ui/Input";
import Button from "../Button";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useGetConsumers } from "../../hooks/api/GroupDiscount/searchConsumer";
import { ErrorMessage } from "@hookform/error-message";
import { useDebounceValue } from "../../hooks/useDebounce";
import DropDown from "../ui/dropdown";
import { useAddGroupDiscount } from "../../hooks/api/GroupDiscount/addGroupDiscount";
import { useGetGroupDiscountDetail } from "../../hooks/api/GroupDiscount/getGroupDiscountDetail";
import { usePathcGroupDiscount } from "../../hooks/api/GroupDiscount/patchGroupDiscount";
import { useGetProviders } from "../../hooks/api/GroupDiscount/searchProvider";

const CUSTOMER_TYPE = [
    { id: "Staff", name: "Staff" },
    { id: "Customer", name: "Customer" },
];

export type TGroupDiscount = {
    id?: number;
    consumer: any;
    provider: any;
    user_type: "Staff" | "Customer";
    discount: string;
    expiry_date: Date;
};

const AddGroupDiscountForm = ({
    activeDiscount,
    onHide
}: {
    activeDiscount: number | null,
    onHide: () => void
}) => {
    const isEdit = useMemo(() => Boolean(activeDiscount), [activeDiscount]);
    const { data, isSuccess: isDetailSuccess } = useGetGroupDiscountDetail(activeDiscount);
    const [search, setSearch] = useState("");
    const [searchProvider, setSearchProvider] = useState("");


    const searchValue = useDebounceValue(search);
    const searchProviderValue = useDebounceValue(searchProvider);

    const { mutate: addGroupDiscount, isSuccess } = useAddGroupDiscount();
    const { mutate: addPatchDiscount, isSuccess: isPatchSuccess } = usePathcGroupDiscount();

    const { data: consumersList } = useGetConsumers(useMemo(()=>searchValue,[searchValue]));
    const { data: providersList } = useGetProviders(useMemo(()=>searchProviderValue,[searchProviderValue]));


    const [selectedConsumer, setSelectedConsumer] = useState<any>([]);
    const [selectedProvider, setSelectedProvider] = useState<any>([]);


    const {
        register,
        formState: { errors },
        setValue,
        handleSubmit,
    } = useForm<TGroupDiscount>({
        mode: "onBlur",
    });

    const onFormSubmit = (data: TGroupDiscount) => {
        if (isEdit) {
            addPatchDiscount(data)
        } else {
            addGroupDiscount(data);
        }
    };

    useEffect(() => {
        if (isSuccess || isPatchSuccess) {
            onHide();
        }
    }, [isSuccess, isPatchSuccess]);

    useEffect(() => {
        if (data) {
            setSelectedConsumer([{ ...data?.consumer }]);
            setValue("consumer", data?.consumer?.id);

            setSelectedProvider([{ ...data?.provider }]);
            setValue("provider", data?.provider?.id)

            setValue("user_type", data?.user_type);
            setValue("discount", data?.discount);
            const formattedDate = data?.expiry_date &&
                new Date(data.expiry_date).toISOString().split('T')[0];
            setValue("expiry_date", formattedDate);
            setValue("id", data?.id);
        }
    }, [isDetailSuccess, data, setValue]);


    return (
        <>
            <Form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="justify-content-between pt-2">

                    <Form.Group>
                        <Form.Label>Provider</Form.Label>
                        <AsyncTypeahead
                            {...register("provider", {
                                required: "Required",
                            })}
                            id="Provider-search-box"
                            filterBy={() => true}
                            isLoading={false}
                            labelKey={"name"}
                            minLength={3}
                            onSearch={(data) => setSearch(data)}
                            options={providersList?.results}
                            placeholder={"Search provider..."}
                            selected={selectedProvider}
                            onChange={(selected: any) => {
                                setValue("provider", selected[0]?.id);
                                setSelectedProvider(selected.length ? [selected[0]] : []);
                            }}
                            renderMenuItemChildren={(node: any) => (
                                <Fragment>
                                    {node.id !== null && node.name && (
                                        <Stack direction="horizontal">
                                            <img
                                                alt={node.name}
                                                src={node.logo || "/img/profile.png"}
                                                style={{
                                                    height: "32px",
                                                    marginRight: "10px",
                                                    width: "32px",
                                                }}
                                            />
                                            <Stack direction="vertical">
                                                <span>{node.name}</span>
                                            </Stack>
                                        </Stack>
                                    )}
                                </Fragment>
                            )}
                        />
                        {errors && (
                            <ErrorMessage
                                errors={errors}
                                name={"provider"}
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type} className="text-danger">
                                            {message}
                                        </p>
                                    ))
                                }
                            />
                        )}
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Consumer</Form.Label>
                        <AsyncTypeahead
                            {...register("consumer", {
                                required: "Required",
                            })}
                            id="Card-search-box"
                            filterBy={() => true}
                            isLoading={false}
                            labelKey={"name"}
                            minLength={3}
                            onSearch={(data) => setSearchProvider(data)}
                            options={consumersList?.results}
                            placeholder={"Search consumer..."}
                            selected={selectedConsumer}
                            onChange={(selected: any) => {
                                setValue("consumer", selected[0]?.id);
                                setSelectedConsumer(selected.length ? [selected[0]] : []);
                            }}
                            renderMenuItemChildren={(node: any) => (
                                <Fragment>
                                    {node.id !== null && node.name && (
                                        <Stack direction="horizontal">
                                            <img
                                                alt={node.name}
                                                src={node.logo || "/img/profile.png"}
                                                style={{
                                                    height: "32px",
                                                    marginRight: "10px",
                                                    width: "32px",
                                                }}
                                            />
                                            <Stack direction="vertical">
                                                <span>{node.name}</span>
                                            </Stack>
                                        </Stack>
                                    )}
                                </Fragment>
                            )}
                        />
                        {errors && (
                            <ErrorMessage
                                errors={errors}
                                name={"consumer"}
                                render={({ messages }) =>
                                    messages &&
                                    Object.entries(messages).map(([type, message]) => (
                                        <p key={type} className="text-danger">
                                            {message}
                                        </p>
                                    ))
                                }
                            />
                        )}
                    </Form.Group>

                    <DropDown
                        name="user_type"
                        className="mt-2"
                        label="Type"
                        register={register}
                        options={CUSTOMER_TYPE}
                        selectedValues="Staff"
                        isMultiple={false}
                    />
                    <Form.Group>
                        <Input
                            register={register}
                            label="Discount"
                            name="discount"
                            type="number"
                            placeholder="Discount in %"
                            error={errors?.discount?.message}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Input
                            register={register}
                            label="Expiry Date"
                            name="expiry_date"
                            type="date"
                            placeholder="Expiry Date"
                            error={errors?.expiry_date?.message}
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            type="submit"
                            label="SUBMIT"
                            className="m-2 btn btn-secondary"
                        />
                    </div>
                </div>
            </Form>
        </>
    );
};

export default AddGroupDiscountForm;
