import {
  faBlog,
  faBuilding,
  faDashboard,
  faGift,
  faIdCard,
  faTag,
  faTasks,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { ROLES, getActiveCompanyId } from "./Utils";

export enum PageRoutes {
  LOGIN = "/login",
  LOGOUT = "/logout",
  DASHBOARD = "/",
  COMPANY = "/company/",
  LOYALTY_CARD = "/loyalty-card",
  USERS = "/users",
  EMPLOYEES = "/employees",
  CUSTOMERS = "/customers",
  ACTIVITY_LOG = "/activity-log",
  CUSTOMER_LOYALTY = "/loyalty/customer/:id",
  COMPANY_BANNERS = "/company/:id/banners",
  SCRATCH_CARD = "/scratch-card",
  COMPANY_CREDIT_LOG="/company/:id/credit-log",
  SCRATCH_CARD_USERS = "/scratch-card/:id/users",
  SCRATCH_CARD_REWARDS = "/scratch-card/:id/rewards",
  REDEEM_REWARD="/redeem-reward/",
  GROUP_DISCOUNT="/group-discount/",
  CREDIT_LOG="/credit-log",
  BLOGS="/blogs",
  ADD_BLOG="/blogs/create",
  EDIT_BLOG="/blogs/:id/edit",



}

export const SidebarMenu = [
  {
    feature: "Dashboard",
    href: PageRoutes.DASHBOARD,
    roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.MANAGER, ROLES.CASHIER],
    icon: faDashboard,
  },
  {
    feature: "Company",
    href: PageRoutes.COMPANY,
    roles: [ROLES.SUPERADMIN],
    icon: faBuilding,
  },
  {
    feature: "Users",
    href: PageRoutes.USERS,
    roles: [ROLES.SUPERADMIN],
    icon: faUsers,
  },
  {
    feature: "Group Discount",
    href: PageRoutes.GROUP_DISCOUNT,
    roles: [ROLES.SUPERADMIN,ROLES.ADMIN,ROLES.MANAGER],
    icon: faBuilding,
  },
  {
    feature: "Loyalty card",
    href: PageRoutes.LOYALTY_CARD,
    roles: [ROLES.ADMIN, ROLES.MANAGER],
    icon: faIdCard,
  },
  {
    feature: "Scratch Card",
    href: PageRoutes.SCRATCH_CARD,
    roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.MANAGER],
    icon: faGift,
  },
  {
    feature: "Redeem Reward",
    href: PageRoutes.REDEEM_REWARD,
    roles: [ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.MANAGER],
    icon: faTag,
  },
  {
    feature: "Employees",
    href: PageRoutes.EMPLOYEES,
    roles: [ROLES.ADMIN, ROLES.MANAGER],
    icon: faUsers,
  },
  {
    feature: "Customers",
    href: PageRoutes.CUSTOMERS,
    roles: [ROLES.ADMIN, ROLES.MANAGER],
    icon: faUsers,
  },
  {
    feature: "Credit Log",
    href: PageRoutes.CREDIT_LOG,
    roles: [ROLES.SUPERADMIN,ROLES.ADMIN, ROLES.MANAGER],
    icon: faTasks,
  },
  {
    feature: "Activity Log",
    href: PageRoutes.ACTIVITY_LOG,
    roles: [ROLES.ADMIN, ROLES.MANAGER],
    icon: faTasks,
  },
  {
    feature: "Blogs",
    href: PageRoutes.BLOGS,
    roles: [ROLES.SUPERADMIN],
    icon: faBlog,
  },
];

export const DATA_ADMIN_USER = "admin-user-data";
export const ADMIN_USER = "admin-user";

export const BASE_PATH = process.env.REACT_APP_API_URL;
export const PROFILE_IMAGE = "/img/profile.png";
export const API_GET_TOKEN = "api/dashboard/token/";
export const API_GET_COMPANY = "api/company/";
export const API_GET_ROLE = "api/role/";
export const API_GET_ACCOUNT = "api/token/";
export const API_GET_PRODUCT = `api/company/${getActiveCompanyId()}/product`;
export const API_GET_USER_DETAIL = "api/account/me";
export const API_GET_TOPUSERS = "api/reputation/top/";
export const DATA_ACTIVE_COMPANY_SESSION = "active-company-session";
export const API_ACCOUNT = "api/account/";
export const API_POST_COMPANY = "api/company/";
export const API_POST_PRODUCT = "product/";
export const API_DASHBOARD_OVERVIEW = "api/dashboard/overview";
export const API_GET_COMPANY_DETAIL = "api/company";
export const API_GET_SIGNUP_DATA_ = "api/dashboard/signup";
export const API_DASHBOARD = "api/dashboard/";
export const API_PROJECT_IMAGE = "api/project/photo/";

export const API_FORGET_PASSWORD = "api/account/password/reset/link/";
export const API_RESET_PASSWORD = "api/account/password/reset/confirm/";

export const API_ABOUT = "api/page/1";
export const API_REPORT = "api/report/";

export const API_CATEGORY = "api/category/";

export const API_ERROR_MESSAGE =
  "Something went wrong. Please contact developer.";

export const requiredError = "This field is required";

export const setCategoryUrl = (companyId: number) => {
  return `api/company/${companyId}/company-category/`;
};

export const getCompanyTransactionListUrl = (
  companyId: number,
  phone: string,
  redeemed: any,
  loyaltyId: any,
  customerId: any
) => {
  const starter = `api/company/${companyId}/user-transaction-activity/`;
  let base = `api/company/${companyId}/user-transaction-activity/`;

  if (redeemed !== "") {
    base = base + `?redeemed=${redeemed}`;
  }

  if (phone !== "") {
    if (base === starter) base = base + `?phone=${phone}`;
    else base = base + `&phone=${phone}`;
  }

  if (loyaltyId !== "") {
    if (base === starter) base = base + `?loyalty_card=${loyaltyId}`;
    else base = base + `&loyalty_card=${loyaltyId}`;
  }

  if (customerId !== "") {
    if (base === starter) base = base + `?user=${customerId}`;
    else base = base + `&user=${customerId}`;
  }

  return base;
};

export enum MessageStrings {
  ERROR_MESSAGE_TITLE = "Something went wrong!",
  ERROR_MESSAGE_GENERIC = "An issue has occurred. Please try again later or contact support.",
}
