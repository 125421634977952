"use client";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../../components/ui/Input";
import { Form } from "react-bootstrap";
import Button from "../../components/Button";
import HeadingTitle from "../../components/HeadingTitle";
import { BlogSchema, BlogSchemaEdit, TBlog } from "../../common/validation/BlogSchema";
import DropDown from "../../components/ui/dropdown";
import Editor from "../../components/Editor";
import { useAddBlog } from "../../hooks/api/Blogs/addBlog";
import { usePatchBlog } from "../../hooks/api/Blogs/patchBlogDetail";
import { useGetBlogDetails } from "../../hooks/api/Blogs/getBlogDetail";
import ImageUpload from "../../components/ImageUpload";

const STATUS_TYPE = [
    { id: "Published", name: "Published" },
    { id: "Draft", name: "Draft" },
    { id: "Archived", name: "Archived" },
]

const BlogForm = () => {
    const { id }: any = useParams();
    const navigate = useNavigate();

    const {
        data,
        isSuccess: isGetSuccess,
    } = useGetBlogDetails(Number(id));

    const { mutate: addBlog, isSuccess } = useAddBlog();
    const { mutate: mutationUpdate, isSuccess: isUpdateSuccess } = usePatchBlog();

    const isEdit = useMemo(() => Boolean(id), [id]);
    const {
        register: register,
        formState: { errors: errors },
        handleSubmit: handleSubmit,
        setValue,
        watch,
    } = useForm<TBlog>({
        resolver: zodResolver(
            isEdit ? BlogSchemaEdit : BlogSchema
        ),
        mode: "onBlur",
        defaultValues: {
            tags: "",
            content:"",
        }
    });

    const onEditorStateChange = (editorState: any) => {
        setValue("content", editorState);
    };

    const onFormSubmit = (data: any) => {
        console.log(data, "dataa");

        const bodyFormData = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (key !== "featured_image") {
                bodyFormData.append(key, String(value));
            }
        });

        if (
            data?.featured_image &&
            typeof data?.featured_image !== "string" &&
            data?.featured_image[0] instanceof File
        ) {
            console.log(data?.featured_image[0], "fatured image");
            bodyFormData.append("featured_image", data?.featured_image[0]);
        }

        if (isEdit) {
            mutationUpdate({ blog_id: Number(id), data: bodyFormData });
        } else {
            addBlog(bodyFormData);
        }
    };

    useEffect(() => {
        if (data) {
            setValue("title", data?.title);
            setValue("slug", data?.slug);
            setValue("excerpt", data?.excerpt);
            setValue("content", data?.content);
            setValue("featured_image", data?.featured_image);
            setValue("meta_description", data?.meta_description);
            setValue("meta_keywords", data?.meta_keywords);
            setValue("status", data?.status);
            setValue("tags", data?.tags);
        }
    }, [isGetSuccess]);

    useEffect(() => {
        if (isSuccess || isUpdateSuccess) {
            navigate("/blogs");
        }
    }, [isSuccess, isUpdateSuccess]);
    return (
        <div className="p-4 col-sm-5">
            <div>
                <HeadingTitle title={`${isEdit ? 'Update' : 'Add'} Blog`} />
            </div>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="d-flexpt-2">
                    <Form.Group>
                        <Input
                            register={register}
                            label="Title"
                            name="title"
                            type="text"
                            placeholder="Enter title"
                            error={errors?.title?.message}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Input
                            register={register}
                            label="Slug"
                            name="slug"
                            type="text"
                            placeholder="Enter slug"
                            error={errors?.slug?.message}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Input
                            register={register}
                            label="Excerpt"
                            name="excerpt"
                            type="text"
                            placeholder="Enter excerpt"
                            error={errors?.excerpt?.message}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="fw-bold mt-2">Content</Form.Label>
                        <Editor value={watch("content") || ""} onChange={onEditorStateChange} />
                        {errors?.content?.message && <p className="text-base text-danger">*{errors?.content?.message}</p>}
                    </Form.Group>
                    <Form.Label className="fw-bold mt-2">Featured Image</Form.Label>
                    <div className="cocl-sm-6">
                        <ImageUpload
                            register={register}
                            name="featured_image"
                            error={errors?.featured_image?.message}
                            file={watch("featured_image")}
                            validate={() => null}
                            buttonLabel="Featured Image"
                            label="Featured Image"
                        />
                    </div>
                    <Form.Group>
                        <Input
                            name="tags"
                            className="mt-2"
                            label="Tags"
                            type="text"
                            register={register}
                            placeholder="Enter tags"
                            error={errors?.tags?.message}
                        />
                    </Form.Group>
                    <Form.Group>
                        <DropDown
                            name="status"
                            className="mt-2"
                            label="Status"
                            register={register}
                            options={STATUS_TYPE}
                            selectedValues="Published"
                            isMultiple={false}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Input
                            register={register}
                            label="Meta description"
                            name="meta_description"
                            type="textarea"
                            as="textarea"
                            rows={3}
                            placeholder="Enter meta description"
                            error={errors?.meta_description?.message}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Input
                            register={register}
                            label="Meta keywords"
                            name="meta_keywords"
                            type="textarea"
                            as="textarea"
                            rows={3}
                            placeholder="Enter meta keywords"
                            error={errors?.meta_keywords?.message}
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            type="submit"
                            label={isEdit ? `UPDATE` : "SUBMIT"}
                            className="m-2 btn btn-secondary"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BlogForm;
