import * as z from "zod";

const MAX_FILE_SIZE = 5000000;

export const BlogSchema = z.object({
  title: z.string().min(1, { message: "Title is required" }),
  slug: z.string().min(1, { message: "Slug is required" }),
  content: z.string().min(1, { message: "Content is required" }),
  excerpt: z.string().min(1, { message: "Excerpt is required" }),
  published_date: z.string().optional(),
  featured_image: z.any().optional(),
  meta_description: z.string().nullable().optional(),
  meta_keywords: z.string().nullable().optional(),
  status: z.string().min(1, { message: "Status is required" }),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  user: z.number().int().optional(),
  tags: z.string().optional(),
});

export const BlogSchemaEdit = z.object({
  id: z.number().int().optional(),
  title: z.string().min(1, { message: "Title is required" }),
  slug: z.string().min(1, { message: "Slug is required" }),
  content: z.string().min(1, { message: "Content is required" }),
  excerpt: z.string().min(1, { message: "Excerpt is required" }),
  published_date: z.string().optional(),
  featured_image: z
    .any()
    .optional()
    .refine((files: any) => {
      if (typeof files === "string") {
        return true;
      }
      if (Array.isArray(files) && files.length > 0) {
        return files[0]?.size <= MAX_FILE_SIZE;
      }
      return true;
    }, `Max image size is 5MB.`),
  meta_description: z.string().nullable().optional(),
  meta_keywords: z.string().nullable().optional(),
  status: z.string().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
  user: z.number().int().optional(),
  tags: z.string().optional(),
});

export type TBlog = z.infer<typeof BlogSchema>;
export type TBlogEdit = z.infer<typeof BlogSchemaEdit>;
