export const useLocalStorage = (key: string): any => {
  const setItem = (value: unknown): boolean => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const getItem = (): boolean => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : undefined;
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const removeItem = (): boolean => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  return { setItem, getItem, removeItem };
};
