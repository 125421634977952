import { useLocation } from "react-router-dom";
import {
  ADMIN_USER,
  DATA_ACTIVE_COMPANY_SESSION,
  DATA_ADMIN_USER,
} from "./Constants";
import _ from "lodash";
import { Company } from "../class/company";
import { slot_item } from "../class/LoyaltyCard";
import imageCompression from "browser-image-compression";
import { PaginationState } from "@tanstack/react-table";
export const validateEmail = (mail: any): boolean => {
  const mailFormat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;
  if (mailFormat.test(mail)) {
    return true;
  }
  return false;
};
export const getLocalDateTime = (timestamp: any): string => {
  const date = new Date(timestamp);
  return date.toLocaleDateString();
  // + " | " + date.toLocaleTimeString();
};

export const getFormatedLocalDateTime = (timestamp: any): string => {
  const date = new Date(timestamp);
  return date.toDateString();
};



export function convertUTCToLocalDateTimeInput(utcDateTimeString: null) {
  // Create a Date object from the UTC date and time string
  const utcDate: any = new Date(utcDateTimeString + "Z"); // 'Z' indicates UTC time

  // Ensure the date is valid
  if (isNaN(utcDate)) {
    return null;
  }

  // Get the local date components
  const year = utcDate.getFullYear();
  const month = String(utcDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(utcDate.getDate()).padStart(2, "0");
  const hours = String(utcDate.getHours()).padStart(2, "0");
  const minutes = String(utcDate.getMinutes()).padStart(2, "0");

  // Construct the local datetime string in the format required by datetime-local
  const localDateTimeString = `${year}-${month}-${day}T${hours}:${minutes}`;

  return localDateTimeString;
}

export const formatDate = (dateString: any) => {
  const date = new Date(dateString);

  // Extract the month, day, and year from the Date object
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth is zero-based
  const day = String(date.getUTCDate()).padStart(2, "0");
  const year = date.getUTCFullYear();

  // Format the date as mm/dd/yyyy
  return `${month}/${day}/${year}`;
};

export const setAdminUserData = (data: any): boolean => {
  localStorage.setItem(DATA_ADMIN_USER, JSON.stringify(data));
  return true;
};

export const getAdminUserData = (): any => {
  const sessionData = localStorage.getItem(DATA_ADMIN_USER);
  return sessionData;
};

export const getUserNameData = (): string => {
  try {
    const sessionData =
      localStorage.getItem(DATA_ADMIN_USER) || "{'username':'admin'}";
    const user = JSON.parse(sessionData).username;
    return user;
  } catch (e) {
    return "admin";
  }
};

export const getUserId = (): number => {
  const sessionData = localStorage.getItem(DATA_ADMIN_USER) || "{'id':'null'}";
  const userid = JSON.parse(sessionData).id;
  return userid;
};

export const convertSingleDigitToDouble = (data: number): string => {
  const stringData = "" + data;
  if (stringData.length === 1) {
    return "0" + data;
  }
  return stringData;
};

export const convertStarValue = (number: number): number => {
  return number / 0.05;
};

export const checkDateInterval = (
  start_date_str: string,
  end_date_str: string
): any => {
  // Convert date strings to Date objects
  const start_date = new Date(start_date_str);
  const end_date = new Date(end_date_str);
  // Get the current date
  const current_date = new Date();
  // Check if there are 7 or fewer days left until the end date
  const time_difference = end_date.getTime() - current_date.getTime();
  const days_left = Math.ceil(time_difference / (1000 * 3600 * 24));
  // Check if current date is within the date interval
  if (current_date >= start_date && current_date <= end_date && days_left > 7) {
    return "Active";
  } else if (days_left <= 7 && days_left > 0) {
    return days_left + " day left";
  } else if (days_left < 0) {
    return "Expired";
  } else if (current_date <= start_date) {
    const time_difference = start_date.getTime() - current_date.getTime();
    const days_left_to_join = Math.ceil(time_difference / (1000 * 3600 * 24));
    return days_left_to_join + " day to join";
  }
};

export const isSubscriptionActive = (
  start_date_str: string,
  end_date_str: string
): boolean => {
  const start_date = new Date(start_date_str);
  const end_date = new Date(end_date_str);
  const current_date = new Date();
  if (current_date >= start_date && current_date <= end_date) {
    return true;
  } else {
    return false;
  }
};

export const getActiveCompanyId = (): any => {
  try {
    const sessionData = localStorage.getItem("active-company-session") || null;
    if (sessionData) {
      // eslint-disable-next-line no-var
      var id = JSON.parse(sessionData).id;
    } else {
      return null;
    }
    return id;
  } catch (e) {
    return null;
  }
};

export const getCompanyRoles = (): any => {
  try {
    const sessionData = localStorage.getItem(ADMIN_USER) || null;
    if (sessionData) {
      return JSON.parse(sessionData).user.company_roles;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};
export const isSuperUser = (): boolean | null => {
  try {
    const sessionData = localStorage.getItem(ADMIN_USER) || null;
    if (sessionData) {
      const data = JSON.parse(sessionData);
      return data.user.is_superuser;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};
export const checkCompanyRoles = (roles: string[]): boolean => {
  const companyRoles = getCompanyRoles();
  if (roles.includes(ROLES.SUPERADMIN) && isSuperUser()) {
    return true;
  } else if (
    !_.isNil(getActiveCompanyId()) &&
    !_.isNil(isSuperUser()) &&
    _.isEmpty(companyRoles)
  ) {
    return true;
  }
  let result = false;
  if (!_.isNil(companyRoles) && companyRoles?.length > 0) {
    companyRoles?.forEach((node: any) => {
      if (roles.includes(node.role.name)) {
        result = true;
      }
    });
  }
  return result;
};

export const getUserTypeDetail = (): any => {
  try {
    const sessionData = localStorage.getItem(ADMIN_USER) || null;
    if (sessionData) {
      const data = JSON.parse(sessionData);
      return data.user;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

export const usePathname = (): string => {
  const location = useLocation();
  return location.pathname;
};

export const parseErrorMessage = (data: any) => {
  if (typeof data === "object") {
    const firstKey = Object.keys(data)[0];
    if (firstKey) {
      return data[`${firstKey}`];
    }
  } else {
    return "Error";
  }
};

export const ROLES = {
  SUPERADMIN: "superadmin",
  MANAGER: "manager",
  ADMIN: "admin",
  CASHIER: "cashier",
};

export const getCompanyId = (): number | null => {
  try {
    const sessionData = localStorage.getItem(ADMIN_USER) || null;
    if (sessionData) {
      return JSON.parse(sessionData).user.company_roles[0].company.id;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};
export const saveActiveCompanyToLocalStorage = (data: any): boolean => {
  localStorage.setItem(DATA_ACTIVE_COMPANY_SESSION, JSON.stringify(data));
  return true;
};

export const getActiveCompanyFromLocalStorage = (): Company | null => {
  try {
    const sessionData =
      localStorage.getItem(DATA_ACTIVE_COMPANY_SESSION) || null;
    if (sessionData) {
      return JSON.parse(sessionData);
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};
export const getActiveUserCompanyRole = (cid: number,is_return_name=false): string | null => {
  const sessionData = localStorage.getItem(ADMIN_USER) || null;
  if (sessionData) {
    const admin_user_data = JSON.parse(sessionData);
    const foundNode = admin_user_data.user.company_roles.find(
      (node: any) => node.company.id === cid
    );
    if (foundNode) {
      return is_return_name?foundNode?.role?.name: foundNode?.role?.id;
    } else {
      return null;
    }
  }
   else {
    return null;
  }
};


export const getCompanyName = (): string | null => {
  try {
    const sessionData = localStorage.getItem(ADMIN_USER) || null;
    if (sessionData) {
      return JSON.parse(sessionData).user.company_roles[0].company.name;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};

export const getTrimmedContent = (name: string, length: number): string => {
  if (name.length > length) {
    return name.substring(0, length) + "...";
  }
  return name;
};

export const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};

export function camalize(str: string): string {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

// export const getSlotImage = (
//   slotItems: slot_item[],
//   index: number,
//   defaultSlotImg: FileList,
// ): string => {
//   let slotItem;

//   if (slotItems.length > 0) {
//     slotItem = slotItems.filter((node) => {
//       return node.slot_no && parseInt(node.slot_no) === index + 1;
//     });
//     if (slotItem.length > 0 && slotItem[0].slot_image) {
//       // if(slotItem[0].slot_image instanceof File){

//       // }
//       return URL.createObjectURL(slotItem[0].slot_image[0]);
//     }
//     // else{
//     //   return "/images/loyalty/default_reward_image.png";
//     // }
//   }
//   if (defaultSlotImg) {
//     return URL.createObjectURL(defaultSlotImg[0]);
//   }
  
//   return "/images/default_slot_image.png";
// };

export const getSlotImageUrl = (
  slotItems: any,
  index: number,
  defaultSlotImg?: any,
  isEdit?:boolean
): any => {
  let slotItem;
  if (slotItems.length > 0) {
    slotItem = slotItems.filter((node: slot_item) => {
      return node.slot_no && parseInt(node.slot_no) === index + 1;
    });
    if (slotItem.length > 0) {
      if (
        typeof slotItem[0].slot_image === "string" && isEdit
      ) {
        return slotItem[0].slot_image;
      }
      else if( typeof slotItem[0].slot_image === "string" && !isEdit){
        return "/images/loyalty/default_reward_image.png";
      }
      else if (slotItem[0].slot_image instanceof FileList && slotItem[0].slot_image[0] ) {
        return URL.createObjectURL(slotItem[0].slot_image[0]);
      } else if (
        typeof slotItem[0].slot_image === "string" &&
        slotItem[0].slot_image.length === 0
      ) {
        if (defaultSlotImg instanceof FileList && defaultSlotImg.length > 0) {
          // Handle the case where you have a fileList
          return URL.createObjectURL(defaultSlotImg[0]);
        } else if (
          typeof defaultSlotImg === "string" &&
          defaultSlotImg.trim() !== ""
        ) {
          // Handle the case where you have a non-empty URL
          return defaultSlotImg;
        }
      }
    }
  }
  if (defaultSlotImg instanceof FileList && defaultSlotImg.length > 0) {
    // Handle the case where you have a fileList
    return URL.createObjectURL(defaultSlotImg[0]);
  } else if (
    typeof defaultSlotImg === "string" &&
    defaultSlotImg.trim() !== ""
  ) {
    // Handle the case where you have a non-empty URL
    return defaultSlotImg;
  }
  return "/images/default_slot_image.png";
};

export const handleImageUpload = async (file: any): Promise<any> => {
  const type = typeof file;
  if (type !== "object") {
    return null;
  }
  try {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    return await imageCompression(file, options);
  } catch (error) {
    return null;
  }
};

export const handleCardImageUrls = (imageValue: any): any => {
  if (imageValue === "" || imageValue == null) {
    return "/images/loyalty/default_card_placeholder.png";
  } else if (imageValue instanceof FileList && imageValue.length > 0) {
    // Handle the case where you have a fileList
    return URL.createObjectURL(imageValue[0]);
  } else if (typeof imageValue === "string" && imageValue.trim() !== "") {
    // Handle the case where you have a non-empty URL
    return imageValue;
  }
  // return "/images/loyalty/loyalty-bg.jpg";
};

export const handleCardImageUrlsBack = (imageValue: any): any => {
  if (imageValue === "" || imageValue == null) {
    return "/images/loyalty/default_card_placeholder.png";
  } else if (imageValue instanceof FileList && imageValue.length > 0) {
    // Handle the case where you have a fileList
    return URL.createObjectURL(imageValue[0]);
  } else if (typeof imageValue === "string" && imageValue.trim() !== "") {
    // Handle the case where you have a non-empty URL
    return imageValue;
  }
  // return "/images/loyalty/loyalty-bg.jpg";
};

export const cn = (...classes: string[]): string => {
  return classes.filter(Boolean).join(" ");
};


export const isSubscriptionFree = () => {
  const company = localStorage.getItem('active-company-session');
  if (company) {
    const companyData = JSON.parse(company);
    if( String(companyData?.subscription) === "Free"){
      return true;
    }
  }
    return false;
}


export const getActiveCompanyLogo = () => {
  const company = localStorage.getItem('active-company-session');
  if (company) {
    const companyData = JSON.parse(company);
    return companyData?.logo_url;
  }
  return null;
}


export const createQueryString = ({ pageIndex, pageSize }:PaginationState, searchValue:string) => {
  const query = new URLSearchParams();
  query.append("page", String(pageIndex + 1));
  query.append("page_size", String(pageSize));
  if (searchValue) {
    query.append("search", searchValue);
  }
  return query.toString();
};