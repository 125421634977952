import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import LoadingView from "../LoadingView";

const AuthLayout = () => (
  <Suspense fallback={<LoadingView />}>
    <Outlet />
  </Suspense>
);
export default AuthLayout;
