import { Suspense, lazy } from "react";
import "./App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MainLayout from "./components/Layout/MainLayout";
import { AuthProvider, useAuth } from "./components/AuthProvider";
import { CommonProvider } from "./components/Context/CommonContext";
import RequireAuth from "./components/Auth/RequireAuth";
import AuthLayout from "./components/Layout/AuthLayout";
const Loading = lazy(() => import("./components/Loading"));
const ToastComponent = lazy(() => import("./components/Toast"));
import { ROLES } from "./common/Utils";
import { PageRoutes } from "./common/Constants";
import GroupDiscounts from "./pages/GroupDiscounts/GroupDiscounts";
import CreditLog from "./pages/CreditLog/CreditLog";
import BlogList from "./pages/Blogs/BlogList";
import BlogForm from "./pages/Blogs/BlogForm";
const LoyaltyCardList = lazy(() => import("./pages/LoyaltyCard/LoyaltyCardList"));
const Employees = lazy(() => import("./pages/Employees/Employees"));
const UserLists = lazy(() => import("./pages/Users/UserLists"));
const CustomerLists = lazy(() => import("./pages/Customer/CustomerList"));
const CustomerDetail = lazy(() => import("./pages/Customer/CustomerDetail"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));

const BannerList = lazy(() => import("./pages/Banners/BannerList"));
const ActivityLog = lazy(() => import("./pages/ActivityLog/ActivityLog"));
const ScratchCardList = lazy(() => import("./pages/ScratchCard/ScratchCardList"));
const ScratchRewardList = lazy(() => import("./pages/ScratchReward/ScratchRewardList"));
const ScratchCardUserList = lazy(() => import("./pages/ScratchCardUsers/ScratchCardUserList"));
const RedeemedRewardForm = lazy(() => import("./pages/RedeemedReward/RedeemedRewardForm"));
const Unauthorized = lazy(() => import("./pages/Unauthorized"));
const About = lazy(() => import("./pages/About"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
const Login = lazy(() => import("./pages/Login/Login"));
const ForgetPassword = lazy(() => import("./pages/ForgetPassword"));
const MessageModal = lazy(() => import("./components/MessageModal"));
const CompanyList = lazy(
  () => import("./pages/Company/AddCompany/CompanyList")
);
const LoadingView = lazy(() => import("./components/LoadingView"));
function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
        <CommonProvider>
          <Helmet>
            <title>Rewardiz Admin</title>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
              href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap"
              rel="stylesheet"
            />
            <link
              rel="stylesheet"
              href="https://unpkg.com/bootstrap-icons@1.8.3/font/bootstrap-icons.css"
            />
          </Helmet>
          <div className="App">
            <Routes>
              <Route
                element={
                  <ProtectedRoute>
                    <MainLayout />
                  </ProtectedRoute>
                }
              >
                {/* super admin roles */}
                <Route
                  element={<RequireAuth allowedRoles={[ROLES.SUPERADMIN]} />}
                >
                  <Route path={PageRoutes.COMPANY} element={<CompanyList />} />
                  <Route path={PageRoutes.USERS} element={<UserLists />} />
                  <Route
                    path={PageRoutes.COMPANY_BANNERS}
                    element={<BannerList />}
                  />
                  <Route
                    path={PageRoutes.SCRATCH_CARD}
                    element={<ScratchCardList />}
                  />

                  <Route
                    path={PageRoutes.SCRATCH_CARD_REWARDS}
                    element={<ScratchRewardList />}
                  />
                  <Route
                    path={PageRoutes.SCRATCH_CARD_USERS}
                    element={<ScratchCardUserList />}
                  />

                  <Route
                    path={PageRoutes.COMPANY_CREDIT_LOG}
                    element={
                      <CreditLog />
                    }
                  />
                   <Route
                    path={PageRoutes.BLOGS}
                    element={
                      <BlogList />
                    }
                  />
                   <Route
                    path={PageRoutes.ADD_BLOG}
                    element={
                      <BlogForm />
                    }
                  />
                   <Route
                    path={PageRoutes.EDIT_BLOG}
                    element={
                      <BlogForm />
                    }
                  />
                </Route>
                {/* admin and manager roles */}
                <Route
                  element={
                    <RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]} />
                  }
                >

                </Route>
                <Route
                  element={
                    <RequireAuth allowedRoles={[ROLES.ADMIN, ROLES.MANAGER]} />
                  }
                >
                  <Route
                    path={PageRoutes.GROUP_DISCOUNT}
                    element={<GroupDiscounts />}
                  />
                  <Route path={PageRoutes.EMPLOYEES} element={<Employees />} />
                  <Route
                    path={PageRoutes.CUSTOMERS}
                    element={<CustomerLists />}
                  />

                  <Route
                    path={PageRoutes.ACTIVITY_LOG}
                    element={
                      <ActivityLog customerId={undefined} loyaltyCards={[]} />
                    }
                  />
                  <Route
                    path={PageRoutes.CREDIT_LOG}
                    element={
                      <CreditLog />
                    }
                  />
                  <Route
                    path={PageRoutes.CUSTOMER_LOYALTY}
                    element={<CustomerDetail />}
                  />
                  <Route
                    path={PageRoutes.LOYALTY_CARD}
                    element={<LoyaltyCardList />}
                  />
                  <Route
                    path={PageRoutes.SCRATCH_CARD}
                    element={<ScratchCardList />}
                  />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        ROLES.SUPERADMIN,
                        ROLES.ADMIN,
                        ROLES.MANAGER,
                        ROLES.CASHIER,
                      ]}
                    />
                  }
                >
                  <Route index element={<Dashboard />} />
                  <Route
                    path={PageRoutes.REDEEM_REWARD}
                    element={<RedeemedRewardForm />}
                  />
                </Route>
                <Route
                  path="unauthorized"
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <Unauthorized />
                    </Suspense>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <PageNotFound />
                    </Suspense>
                  }
                />
                <Route
                  path="/about-us/"
                  element={
                    <Suspense fallback={<LoadingView />}>
                      <About />
                    </Suspense>
                  }
                />
              </Route>
              <Route element={<AuthLayout />}>
                <Route path="/login" element={<Login />} />
                <Route path="/forget-password" element={<ForgetPassword />} />
              </Route>
            </Routes>
            <Suspense>
              <Loading />
            </Suspense>
            <Suspense>
              <ToastComponent />
            </Suspense>
            <Suspense>
              <MessageModal />
            </Suspense>
          </div>
        </CommonProvider>
      </AuthProvider>
    </HelmetProvider>
  );
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProtectedRoute = ({ children }: any) => {
  const { token } = useAuth();

  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default App;
