"use client";
import { useEffect, useState } from "react";
import { Badge, Image } from "react-bootstrap";
import { getFormatedLocalDateTime } from "../../common/Utils";
import { PaginationState, createColumnHelper } from "@tanstack/react-table";
import _ from "lodash";
import Button from "../../components/Button";
import TableWithSeverSidePagination from "../../components/ui/Table";
import useDebounceFunc from "../../hooks/useDebounceFunc";
import { useGetBlogList } from "../../hooks/api/Blogs/getBlogList";
import { useDeleteBlog } from "../../hooks/api/Blogs/deleteBlog";
const columnHelper = createColumnHelper<any>();

const STATUS_COLOR: { [key in 'Published' | 'Draft' | 'Archived']: string } = {
  Published: "success",
  Draft: "secondary",
  Archived: "primary"
}

const BlogList = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const handleSearch = useDebounceFunc((value: string) => {
    setSearchValue(value);
  }, 700);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const { data, isLoading, refetch } = useGetBlogList(pagination, searchValue);
  const{mutate:deleteBlog}=useDeleteBlog();

  const handleDelete = (data: any) => {
    const message = `Are you sure ?  You want to delete ${data?.title}? ` ;
    const confirmValue: boolean = confirm(message);
    if (confirmValue === true) {
      deleteBlog(Number(data?.id));
    }

  }


  useEffect(() => {
    refetch();
  }, [searchValue]);



  const columns = [
    columnHelper.accessor("id", {
      header: "ID",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("sn", {
      header: "SN",
      cell: (info) => info.row.index + 1,
    }),


    columnHelper.accessor("title", {
      header: `Title`,
      cell: (info) => {
        return <div className="text-limit-one-line">{info?.getValue()}</div>;
      },
    }),
    columnHelper.accessor("featured_image", {
      header: `Feautred Image`,
      cell: (info) => {
        return <>
          <Image
            src={
              !_.isNil(info.getValue())
                ? info.getValue()
                : "/images/default_placeholder.png"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = "/images/default_placeholder.png";
            }}
            width={35}
            height={35}
            alt="Company Logo"
            className="pointer"
          />
        </>;
      },
    }),
    columnHelper.accessor("tags", {
      header: `Tags`,
      cell: (info) => {
        return <div className="text-limit-one-line">{info?.getValue()}</div>;
      },
    }),
    columnHelper.accessor("excerpt", {
      header: `Excerpt`,
      cell: (info) => {
        return <div className="text-limit-one-line">{info?.getValue()}</div>;
      },
    }),
    columnHelper.accessor("status", {
      header: `Status`,
      cell: (info) => {
        const status = info?.getValue() as 'Published' | 'Draft' | 'Archived';
        return <Badge bg={STATUS_COLOR[status]}>{status}</Badge>;
      },
    }),
    columnHelper.accessor("created_at", {
      header: `Date`,
      cell: (info) => getFormatedLocalDateTime(info.getValue()),
    }),

    columnHelper.accessor("Actions", {
      header: `Actions`,
      cell: (info) => {
        return <>
          <a href={`/blogs/${info.row.original.id}/edit`}>
            <Button
              title="Edit"
              className="btn btn-link"
              iconType="edit"
            />
          </a>
          <Button
            title="Delete"
            className="btn btn-link text-danger"
            iconType="delete"
            onClick={() => {
              handleDelete(info.row.original)
            }}
          />
        </>
      }
    })
  ];
  return (
    <div className="mt-4 mx-4">

      <TableWithSeverSidePagination
        columns={columns}
        data={data?.results}
        rowCount={data?.count}
        pagination={pagination}
        setPagination={setPagination}
        primaryButtonLabel={"Add Blog"}
        link="/blogs/create"
        title="Blog List"
        isLoading={isLoading}
        handleSearch={handleSearch} />
    </div>
  );
};

export default BlogList;
