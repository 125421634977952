import React from "react";
import { Modal } from "react-bootstrap";

type TModalProps = {
  show: boolean;
  onHide: any;
  title: string;
  children: any;
};

const AddModalComponent = ({ show, onHide, title, children }: TModalProps) => {
  return (
    <div>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </div>
  );
};

export default AddModalComponent;
