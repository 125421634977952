import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack } from "react-bootstrap";

const SearchBar = ({
  handleSubmit,
  setSearchValue,
  placeholder,
  hideSearchButton,
}: any) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack direction="horizontal">
          <input
            className="form-control"
            placeholder={placeholder}
            type="search"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          {!hideSearchButton && (
            <Button type="submit" className="m-2 btn-secondary">
              <FontAwesomeIcon className="text-white" icon={faSearch} />
            </Button>
          )}
        </Stack>
      </form>
    </>
  );
};

export default SearchBar;
