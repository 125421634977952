import AdminService from "../services/AdminService";
const getReq = async (url: string): Promise<any> => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(url),
    },
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
};

const patchReq = async (url: string): Promise<any> => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(url),
    },
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
};

const postReq = async (url: string, data: any): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(url),
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
};

const putReq = async (url: string, body: any): Promise<any> => {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    body: JSON.stringify(body),
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
};

const deleteReq = async (url: string): Promise<any> => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(url),
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
};

const formPost = async (url: string, formData: FormData): Promise<any> => {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(url) },
    body: formData,
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
};

const formPut = async (url: string, formData: FormData): Promise<any> => {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(url) },
    body: formData,
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
};
const formPatch = async (url: string, formData: FormData): Promise<any> => {
  const requestOptions = {
    method: "PATCH",
    headers: { ...authHeader(url) },
    body: formData,
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
};
const patchData = async (url: string, data: any): Promise<any> => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(url),
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const authHeader = (url: string): HeadersInit => {
  // return auth header with jwt if user is logged in and request is to the api url
  const user = AdminService.userValue;
  const isLoggedIn = user && user.access;
  // const isApiUrl = url.startsWith(publicRuntimeConfig.apiUrl);
  // if (isLoggedIn && isApiUrl) {
  if (isLoggedIn) {
    return { Authorization: `Bearer ${user.access}` };
  } else {
    return {};
  }
};
const handleResponse = async (response: {
  text: () => Promise<any>;
  ok: any;
  status: number;
  statusText: any;
}): Promise<any> => {
  const text = await response.text();
  const data = text && JSON.parse(text);
  if (!response.ok) {
    if ([401].includes(response.status) && AdminService.userValue) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      AdminService.postLogout();
    }

    return Promise.reject(data);
  }
  return data;
};

const fetchWrapper = {
  get: getReq,
  post: postReq,
  put: putReq,
  delete: deleteReq,
  formPost: formPost,
  patch: patchReq,
  formPut: formPut,
  formPatch: formPatch,
  patchData: patchData,
};

export default fetchWrapper;
