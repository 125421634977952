import { useQuery } from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";
import { PaginationState } from "@tanstack/react-table";

const getBlogsList =async ({ pageIndex, pageSize }: PaginationState, searchValue: string) => {
        const query = new URLSearchParams();
        query.append("page", String(pageIndex + 1));
        query.append("page_size", String(pageSize));
        if (searchValue) {
            query.append("search", searchValue);
        }
        const url = BASE_PATH + `api/blog/?${query}`;
        const res = await fetchWrapper.get(url);
        return res;
    };

export const useGetBlogList = (pagination: PaginationState,searchValue:string) => {
    return useQuery({
        queryKey: [`Blogs`],
        queryFn: () => getBlogsList(pagination,searchValue),
    });
};
