import { useMutation, useQueryClient} from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";
import { useCommon } from "../../../components/Context/CommonContext";

const deleteBlog = async (blog_id: number) => {
    const url = BASE_PATH + `api/blog/${blog_id}`;
    const res = await fetchWrapper.delete(url);
    return res.data;
};

export const useDeleteBlog = () => {
  const { dispatch } = useCommon();
  const queryClient=useQueryClient();
  return useMutation({
    mutationFn: (blog_id: number) => deleteBlog(blog_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`Blogs`]});
      dispatch({
        type: "toast",
        payload: {
          visible: true,
          message: "Deleted successfully",
          success: false,
        },
      });
    },
    onError: (data: any) => {
      for (const key in data) {
        dispatch({
          type: "toast",
          payload: {
            visible: true,
            message: `${data[key][0]}`,
            success: false,
          },
        });
      }

    },
  });
};
