"use-client";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "../ui/Input";
import Button from "../Button";
import { useParams } from "react-router-dom";

import { useAddCompanyScratchCardCredit } from 
"../../hooks/api/ScratchCardCredit/addScratchCardCredit";
import { getActiveCompanyId } from "../../common/Utils";
type TScratchCardCredit = {
    id?: number | undefined;
    credit: number;
}
const AddCreditForm = ({
    onHide
}: {
    onHide: () => void;
}) => {
    const { id }: any = useParams();
    const { mutate: addScratchCardCredit, isSuccess } = useAddCompanyScratchCardCredit();
    const {
        register: register,
        formState: { errors: errors },
        handleSubmit: handleSubmit,
    } = useForm<TScratchCardCredit>({
        mode: "onBlur",
    });

    const onFormSubmit = (data: TScratchCardCredit) => {
            addScratchCardCredit({
                company_id: id || getActiveCompanyId(),
                data: data,
            });
    };

    useEffect(() => {
        if (isSuccess ) {
            onHide();
        }
    }, [isSuccess]);
    return (
        <>
            <Form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="justify-content-between pt-2">
                    <Form.Group>
                        <Input
                            register={register}
                            label="Total Credit"
                            name="credit"
                            type="number"
                            placeholder="credit"
                            error={errors?.credit?.message}
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-2">
                        <Button
                            type="submit"
                            label="SUBMIT"
                            className="m-2 btn btn-secondary"
                        />
                    </div>
                    </div>
            </Form>
        </>
    );
};

export default AddCreditForm;
