import * as React from "react";

type Action =
  | { type: "toggleSuccessScreen"; payload: any }
  | { type: "loading"; payload: any }
  | { type: "toast"; payload: any }
  | { type: "messageModal"; payload: any };
type Dispatch = (action: Action) => void;
type State = {
  successScreen: {
    visible: boolean;
    success: boolean;
    title: string;
    message: string;
    redirectTo: string;
  };
  loading: boolean;
  toast: {
    success: any;
    visible: boolean;
    message: string;
  };
  messageModal: {
    visible: boolean;
    message: string;
    title: string;
    handleClose: any;
  };
};
type CommonProviderProps = { children: React.ReactNode };

const CommonStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function commonReducer(state: State, action: Action) {
  switch (action.type) {
    case "loading": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "toggleSuccessScreen": {
      return {
        ...state,
        successScreen: {
          visible: !state.successScreen.visible,
          success: action.payload.success,
          title: action.payload.title ? action.payload.title : "Success",
          message: action.payload.message ? action.payload.message : "",
          redirectTo: action.payload.redirectTo
            ? action.payload.redirectTo
            : "",
        },
      };
    }
    case "toast": {
      return {
        ...state,
        toast: {
          visible: action.payload.visible,
          message: action.payload.message,
          success: action.payload.success,
        },
      };
    }
    case "messageModal": {
      return {
        ...state,
        messageModal: {
          visible: action.payload.visible,
          message: action.payload.message,
          title: action.payload.title,
          handleClose: action.payload.handleClose,
        },
      };
    }
    // default: {
    //   throw new Error(`Unhandled action type: ${action.type}`)
    // }
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function CommonProvider({ children }: CommonProviderProps) {
  const [state, dispatch] = React.useReducer(commonReducer, {
    successScreen: {
      visible: false,
      success: true,
      title: "Success",
      message: "Transaction was successful",
      redirectTo: "",
    },
    loading: false,
    toast: {
      visible: false,
      message: "",
      success: false,
    },
    messageModal: {
      visible: false,
      message: "",
      title: "",
      handleClose: null,
    },
  });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <CommonStateContext.Provider value={value}>
      {children}
    </CommonStateContext.Provider>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useCommon() {
  const context = React.useContext(CommonStateContext);
  if (context === undefined) {
    throw new Error("useCommon must be used within a CommonProvider");
  }
  return context;
}

export { CommonProvider, useCommon };
