import {  useQuery} from "@tanstack/react-query";
import fetchWrapper from "../../../common/FetchWrapper";
import { BASE_PATH } from "../../../common/Constants";


const getProviders = async (search:string) => {
  const query= new URLSearchParams();
  query.append("provider",String(search));
  const url = BASE_PATH + `api/company/?${query}`;
  const res = await fetchWrapper.get(url);
  return res;

};

export const useGetProviders= (search:string) => {
  return useQuery({
    queryKey: [`provider ${search}`],
    queryFn:()=> getProviders(search),
  });
};
